<template>
    <div>
        <v-dialog
        scrollable
        persistent
        v-model="collapsePopup"
        width="70%"
        max-width="100%"
        >
            <v-card  height="100%">
                <v-form ref="form"  v-model="valid" lazy-validation>
                    <v-card-title class="headline">Add New Collapse</v-card-title>
                    <v-card-text class="pa-8">
                        <v-row>
                            <v-col cols="6" class="pb-0">
                                <v-text-field
                                    label="Menu Label *"
                                    outlined
                                    v-model="collapse.menu_label"
                                    class="mx-0 my-0"
                                    required
                                    :rules="[(v) => !!v || 'Menu label is required']"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" class="pb-0" v-if="is_arabic_enabled">
                                <v-text-field
                                label="Menu Label (Ar) *"
                                outlined
                                v-model="collapse.ar_menu_label"
                                dir="rtl"
                                class="mx-0 my-0"
                                required
                                :rules="[(v) => !!v || 'Menu label (ar) is required']"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="pb-0">
                                <v-text-field
                                    label="Collapse title *"
                                    outlined
                                    v-model="collapse.title"
                                    class="mx-0 my-0"
                                    required
                                    :rules="[(v) => !!v || 'Collapse title is required']"
                                ></v-text-field>
                            </v-col>
                                <v-col cols="6" class="pb-0">
                                <v-text-field
                                    v-if="is_arabic_enabled"
                                    label="Collapse Title (AR) *"
                                    outlined
                                    v-model="collapse.ar_title"
                                    class="mx-0 my-0"
                                    dir="rtl"
                                    required
                                    :rules="[(v) => !!v || 'Collapse title (ar) is required']"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pb-0">
                                <v-switch
                                    class="mx-0 my-0"
                                    v-model="collapse.enable_collapse"
                                    label="Enable Collapse"
                                ></v-switch>
                                <p><i><b>Note:</b> After adding collapse, You will be able to add sections in it.</i></p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            class="ma-2 white--text blue-color"
                            @click="closeCollapsePopupHandler"
                            >Close</v-btn
                        >
                        <v-btn
                            text
                            class="ma-2 white--text teal-color"
                            @click="saveOrUpdateCollapse">{{ collapse.id? "Update" : "Save" }}</v-btn
                        >

                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name:"PageCollapseForm",
    props: {
        pageId: { type: Number, default: 0 },
        collapseData: { type: Object, default: null },
        collapsePopup: { type: Boolean, default: false },
        is_arabic_enabled:{ type: Number, default: 0}
    },
    watch: {
        collapsePopup: {
            immediate: true,
            handler(val) {
                console.log(val);
                console.log("page id: ", this.pageId);
                if (this.collapseData && this.collapsePopup) {
                    this.collapse = Object.assign({}, this.collapseData);
                    console.log("this.collapse");
                    console.log(this.collapse);
                } else {
                    console.log("else this.collapse");
                    console.log(this.collapse);
                }
            },
        }
    },
    data() {
        return {
            valid: true,
            collapse: {
                id: null,
                page_id: null,
                menu_label: "",
                ar_menu_label: "",
                title: "",
                ar_title: "",
                enable_collapse: false,
                is_arabic_enabled: 0,
            }
        }
    },
    methods: {
        saveOrUpdateCollapse() {
            if (!this.$refs.form.validate()) {
                this.showError("Please fill all required fields");
                return;
            }
            this.showLoader("Saving");
            if (this.collapse) {
                this.collapse.page_id = this.pageId;
                this.$http.post(`venues/b2c/additional/page/collapse`, this.collapse).then((response) => {
                    this.hideLoader();
                    console.log(response);
                    if (response.status == 200 && response.data.status == true) {
                        this.showSuccess("Collapse saved successfully.");
                        this.closeCollapsePopupHandler();
                    }
                })
                .catch((error) => {
                    this.hideLoader();
                    this.errorChecker(error);
                });
            }
        },
        closeCollapsePopupHandler() {
            this.resetFormFields();
            this.$emit("closeCollapse");
            console.log("close collapse");
        },
        resetFormFields() {
            this.$refs.form.resetValidation();
            let ob = {
                id: null,
                page_id: null,
                menu_label: "",
                ar_menu_label: "",
                title: "",
                ar_title: "",
                enable_collapse: false,
                is_arabic_enabled: 0,
            }
            this.collapse = Object.assign({},ob);
        },

    },
};
</script>