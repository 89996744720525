<template>
  <v-container fluid no-gutters>
    <B2cTabs/>
    <v-row justify="center" class="mt-10">
      <v-card style="width: 100%; min-width: 300px">
        <v-row>
          <v-col cols="11">
            <h2 class="pa-4">
              {{ pageId > 0 ? "Edit Page" : "Add New Page" }}
            </h2>
          </v-col>
          <v-col cols="1">
            <v-btn small to="/b2c/pages" class="ma-2">
              <v-icon small>mdi-backburger</v-icon>
              Back
            </v-btn>
          </v-col>
        </v-row>
        <v-form ref="pageForm">
        <v-row justify="center">
          <v-col cols="6" class="pb-0">
            <v-row>
              <v-col cols="6" class="pb-0">
                <v-text-field
                  label="URL Path (Should be unique)"
                  outlined
                  v-model="pageData.path"
                  :disabled="pageData.is_default == 1 ? true : false"
                  class="ml-4 mr-0 my-0"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pb-0">
                <v-select
                  class="mr-4 my-0"
                  v-model="pageData.parent_id"
                  :items="parentPages"
                  item-value="id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  item-text="name"
                  :label="`Parent Page*`"
                ></v-select>
              </v-col>

              <v-col cols="6" class="pb-0">
                <v-text-field
                  label="Menu Label"
                  outlined
                  v-model="pageData.menu_label"
                  class="ml-4 mr-0 my-0"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pb-0" v-if="is_arabic_enabled">
                <v-text-field
                  label="Menu Label (Ar)"
                  outlined
                  v-model="pageData.ar_menu_label"
                  dir="rtl"
                  class="mr-4 ml-0 my-0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  label="Page title"
                  outlined
                  v-model="pageData.title"
                  class="mx-4 my-0"
                ></v-text-field>
                <v-text-field
                  v-if="is_arabic_enabled"
                  label="Page Title (AR)"
                  outlined
                  v-model="pageData.ar_title"
                  class="mx-4 my-0"
                  dir="rtl"
                ></v-text-field>
                <v-text-field
                  label="Description Title"
                  outlined
                  v-model="pageData.sub_title"
                  class="mx-4 my-0"
                ></v-text-field>
                <v-text-field
                  v-if="is_arabic_enabled"
                  label="Description Title (AR)"
                  outlined
                  v-model="pageData.ar_sub_title"
                  class="mx-4 my-0"
                  dir="rtl"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-switch
                  class="ml-4 my-0"
                  v-model="pageData.isJustified"
                  label="Justify content"
                ></v-switch>
              </v-col>
              <v-col
                cols="4"
                v-if="pageData.is_default && pageData.path != 'home'"
              >
                <v-switch
                  class="my-0"
                  v-model="pageData.show_on_homepage"
                  label="Show on home page"
                ></v-switch>
              </v-col>
              <v-col cols="4" v-if="config_enable_findus">
                <v-switch
                  class="my-0"
                  v-model="pageData.enable_findus"
                  label="Enable Find Us"
                ></v-switch>
              </v-col>
              <v-col cols="4" v-if="!pageData.is_default">
                <v-select
                  class="mr-4 my-0"
                  v-model="pageData.layout_columns"
                  :items="[1, 2, 3]"
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  item-text="layout_columns"
                  label="Layout"
                  outlined
                >
                  <template v-slot:item="{ item }">
                    {{ item }} Column{{ item > 1 ? "s" : "" }}
                  </template>

                  <template v-slot:selection="{ item }">
                    {{ item }} Column{{ item > 1 ? "s" : "" }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row
              v-if="pageData.path == 'memberships' && pageData.is_default == 1"
            >
              <v-col cols="12" class="pb-0 ml-4 my-0">
                <h3>Card header options</h3>
              </v-col>
              <v-col cols="12" class="pb-0 ml-4 my-0">
                <v-radio-group v-model="pageData.card_type" row>
                  <v-radio label="Text" value="text"></v-radio>
                  <v-radio label="Image" value="image"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="6" class="pb-0">
            <image-upload
              @upload="
                (data) => {
                  pageData.cover_image = data;
                }
              "
              @remove="
                () => {
                  pageData.cover_image = null;
                }
              "
              :image_path="pageData.cover_path"
              :height="300"
              class="mr-5"
              defaultImage="event"
              :maxAspectRatio="16 / 9"
            ></image-upload>
            <p class="text-center mt-0 mb-8"><b>Cover image</b></p>
          </v-col>
          <v-col cols="12">
            <div class="pa-4">
              <h2 class="pb-3">Description</h2>
              <div class="pb-4">
                <text-editor
                  @complete="setDescriptionContent"
                  :message="home_desc"
                  style="width: 100%"
                />
<!--                <RichEditor-->
<!--                    v-else-->
<!--                    v-model="pageData.description"-->
<!--                    id="editor"-->
<!--                />-->
              </div>
            </div>
          </v-col>
          <v-col cols="12" v-if="is_arabic_enabled">
            <div class="pa-4">
              <h2 class="pb-3">Description (AR)</h2>
              <div class="pb-4">
                <text-editor
                  @complete="setArDescriptionContent"
                  :message="ar_home_desc"
                  style="width: 100%"
                  dir="rtl"
                />
<!--                <RichEditor-->
<!--                    v-else-->
<!--                    v-model="pageData.ar_description"-->
<!--                    contents-lang-direction="rtl"-->
<!--                    language="ar"-->
<!--                />-->
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" v-if="isShowCarouselFunc()">
            <v-switch
              class="mx-4 my-0"
              v-model="pageData.enable_carousel"
              label="Enable Carousal"
            ></v-switch>
          </v-col>
          <v-col
            cols="12"
            v-if="pageData.enable_carousel && isShowCarouselFunc()"
          >
            <v-row>
              <v-col cols="10">
                <div class="pa-4">
                  <h2>Carousel (sliding) images</h2>
                </div>
              </v-col>
              <v-col cols="2" class="text-right">
                <v-btn
                  small
                  text
                  outlined
                  class="white--text ma-2 blue-color"
                  @click="addNewCarouselHandler"
                >
                  <v-icon color="#fff" small>mdi-plus</v-icon>
                  Add Carousel
                </v-btn>
              </v-col>
            </v-row>
            <div class="pa-4">
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(carousel, index) in pageData.carousels"
                  :key="index"
                  class="px-4 py-3"
                >
                  <v-expansion-panel-header>
                    <h3>
                      Carousel
                      <span
                        v-if="carousel.title && carousel.title != 'null'"
                        class="carousel-subtitle"
                        >({{ carousel.title }})
                      </span>
                    </h3>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="3" class="px-0">
                        <v-switch
                          class="mx-6"
                          v-model="carousel.is_enabled"
                          label="Show on website"
                        ></v-switch>
                      </v-col>
                      <v-col cols="2" class="px-0">
                        <v-switch
                          class="mx-4"
                          v-model="carousel.is_linked"
                          label="Link other page"
                        ></v-switch>
                      </v-col>

                      <v-col cols="2" class="px-0">
                        <v-select
                          class="mx-4"
                          v-model="carousel.background"
                          :items="['primary', 'secondary', 'none']"
                          label="Background"
                          outlined
                        ></v-select>
                      </v-col>

                      <v-col cols="2" class="px-0">
                        <v-select
                          class="mx-4"
                          v-model="carousel.type"
                          :items="carouselTypes"
                          item-text="label"
                          item-value="type"
                          label="Carousel Type"
                          outlined
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="3">
                        <div class="d-flex justify-end">
                          <v-btn
                            class="remove-carousel-btn"
                            depressed
                            small
                            @click="
                              removeCarouselHandler(
                                index,
                                carousel.id ? carousel.id : null
                              )
                            "
                          >
                            <v-icon color="#F44336"
                              >mdi-trash-can-outline
                            </v-icon>
                            Remove Carousel
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-if="carousel.is_linked"
                          label="Link Text"
                          outlined
                          v-model="carousel.link_text"
                          class="my-0"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="3"
                        v-if="carousel.is_linked && is_arabic_enabled"
                      >
                        <v-text-field
                          v-if="carousel.is_linked"
                          label="Link Text (AR)"
                          outlined
                          v-model="carousel.ar_link_text"
                          class="my-0"
                          dir="rtl"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-select
                          v-if="carousel.is_linked"
                          :items="linkedPages"
                          label="Linked Page"
                          outlined
                          v-model="carousel.link_page"
                          class="my-0"
                          hide-details
                        ></v-select>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-if="carousel.link_page == 'external website'"
                          label="External Link"
                          outlined
                          v-model="carousel.external_link"
                          class="my-0"
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          label="Cover title"
                          outlined
                          v-model="carousel.title"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" v-if="is_arabic_enabled">
                        <v-text-field
                          label="Cover title (AR)"
                          outlined
                          v-model="carousel.ar_title"
                          dir="rtl"
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="carousel.type !== 'portfolio'">
                      <v-col cols="12">
                        <h3 class="py-4">Description</h3>
                        <text-editor
                          @complete="
                            (val) => {
                              setCarouselDescription(index, val);
                            }
                          "
                          :message="carouselsDesc[index]"
                          style="width: 100%"
                        />
<!--                        <RichEditor-->
<!--                            v-else-->
<!--                            v-model="pageData.carousels[index].description"-->
<!--                        />-->
                      </v-col>
                      <v-col cols="12" v-if="is_arabic_enabled">
                        <h3 class="py-4">Description (AR)</h3>
                        <text-editor
                          @complete="
                            (val) => {
                              setArCarouselDescription(index, val);
                            }
                          "
                          :message="arCarouselsDesc[index]"
                          style="width: 100%"
                          dir="rtl"
                        />
<!--                        <RichEditor-->
<!--                            v-else-->
<!--                            v-model="pageData.carousels[index].ar_description"-->
<!--                            contents-lang-direction="rtl"-->
<!--                            language="ar"-->
<!--                        />-->
                      </v-col>
                    </v-row>
                    <v-row v-if="carousel.type === 'info'">
                      <v-col cols="6">
                        <v-text-field
                          label="Website"
                          outlined
                          v-model="carousel.website"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="Mobile Number"
                          outlined
                          v-model="carousel.mobile_number"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        [
                          'images',
                          'portfolio',
                          'info',
                          'downloadable',
                        ].includes(carousel.type)
                      "
                    >
                      <v-col cols="12">
                        <h3 class="py-4">Images</h3>
                        <v-row>
                          <v-col cols="3">
                            <div
                              class="upload-btn-main"
                              v-if="
                                carousel.type === 'info' &&
                                carousel.images.length < 1
                              "
                            >
                              <v-btn
                                depressed
                                large
                                class="upload-btn"
                                @click="addNewImageHandler(index)"
                              >
                                Add Banner
                              </v-btn>
                            </div>
                            <div
                              class="upload-btn-main"
                              v-if="carousel.type !== 'info'"
                            >
                              <v-btn
                                depressed
                                large
                                class="upload-btn"
                                @click="addNewImageHandler(index)"
                              >
                                Add new Image
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            sm="6"
                            md="3"
                            cols="12"
                            v-for="(image, key) in carousel.images"
                            :key="key"
                          >
                            <v-row>
                              <v-col cols="12" class="img-main">
                                <v-btn
                                  elevation="3"
                                  depressed
                                  x-small
                                  fab
                                  class="remove-img-btn"
                                  @click="
                                    removeImgHandler(index, key, image.id)
                                  "
                                >
                                  <v-icon color="#fff"
                                    >mdi-trash-can-outline
                                  </v-icon>
                                </v-btn>
                                <image-upload
                                  @upload="
                                    (data) => {
                                      image.image = data;
                                    }
                                  "
                                  @remove="
                                    () => {
                                      image.image = null;
                                      image.image_path = '';
                                    }
                                  "
                                  :image_path="image.image_path"
                                  :height="200"
                                  :width="100"
                                  defaultImage="default"
                                  :isRemoveIcon="false"
                                  :isB2cCarousel="true"
                                ></image-upload>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  label="Image Title"
                                  outlined
                                  v-model="image.subtitle"
                                  class=""
                                ></v-text-field>
                                <v-text-field
                                  v-if="is_arabic_enabled"
                                  label="Image Title (AR)"
                                  outlined
                                  v-model="image.ar_subtitle"
                                  class=""
                                ></v-text-field>
                                <v-text-field
                                  v-if="carousel.type === 'downloadable'"
                                  label="Document Link"
                                  outlined
                                  v-model="image.link_page"
                                  class=""
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <!-- RM CMS -->
                        <v-row
                          v-if="
                            qube_configuration &&
                            carousel.type === 'images' &&
                            pageData.is_default == 1 &&
                            pageData.path == 'home'
                          "
                        >
                          <v-col cols="12">
                            <h3 class="py-4">Partners</h3>
                            <v-row>
                              <v-col cols="3">
                                <v-btn
                                  :disabled="!carousel.id"
                                  depressed
                                  class="mx-4 white--text ma-2 blue-color"
                                  @click="showPartnerPopup(carousel.id)"
                                >
                                  <v-icon color="#fff">mdi-plus</v-icon>
                                  Add new Partner
                                </v-btn>
                                <p
                                  v-if="!carousel.id"
                                  class="mb-0 pt-2 partner-erorr-note"
                                >
                                  Please save the new carousel to add partners.
                                </p>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12">
                                <div class="partners-main">
                                  <v-row>
                                    <v-col
                                      cols="6"
                                      md="3"
                                      sm="4"
                                      v-for="(partner, pi) in carousel.partners"
                                      :key="pi"
                                    >
                                      <v-col
                                        cols="12"
                                        class="d-flex justify-center items-center pa-0"
                                      >
                                        <v-card
                                          class="partner-thumb"
                                          elevation="5"
                                          @click="
                                            showPartnerPopup(
                                              carousel.id,
                                              index,
                                              pi,
                                              'update'
                                            )
                                          "
                                          :class="
                                            !partner.is_enabled
                                              ? 'partner-thumb-gray'
                                              : ''
                                          "
                                          :style="{
                                            'background-image':
                                              'url(' +
                                              getPartnerLogoImage(
                                                partner.logo_path
                                              ) +
                                              ')',
                                          }"
                                        >
                                        </v-card>
                                      </v-col>
                                    </v-col>
                                  </v-row>
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row v-else-if="carousel.type === 'list'">
                      <v-col cols="12">
                        <h3 class="py-4">List</h3>
                      </v-col>
                      <v-col cols="12">
                        <div class="upload-btn-main">
                          <v-btn
                            depressed
                            large
                            class="upload-btn"
                            @click="addNewPointHandler(index)"
                          >
                            Add new Point
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        v-for="(list, listIndex) in carousel.list"
                        :key="listIndex"
                      >
                        <v-text-field
                          label="Point"
                          outlined
                          v-model="list.text"
                          class=""
                          :rules="[(v) => !!v || 'Point is required']"
                        >
                          <template v-slot:append-outer>
                            <v-btn
                              icon
                              @click="removePointHandler(index, listIndex)"
                            >
                              <DeleteIcon />
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <!-- <v-expansion-panels> -->
            </div>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          v-if="qube_configuration == 'afh' && pageData.is_default == 0"
        >
          <v-col cols="12">
            <v-switch
              class="mx-4 my-0"
              v-model="pageData.enable_collapse"
              label="Enable Collapse"
            ></v-switch>
            <p class="mx-4 my-1" v-if="pageData.id == 0">
              <i
                ><b>Note:</b> After adding page, You will be able to add
                collapse in it.</i
              >
            </p>
          </v-col>
          <v-col
            cols="12"
            v-if="
              pageData.enable_collapse &&
              pageData.id > 0 &&
              pageData.is_default == 0
            "
          >
            <PageCollapse
              :pageId="pageData.id"
              :is_arabic_enabled="is_arabic_enabled"
              :facilities="facilities"
            />
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row justify="center" class="my-4">
          <v-col cols="12">
            <v-switch
              class="mx-4 my-0"
              v-model="pageData.enable_seo_meta"
              label="SEO Meta"
            ></v-switch>
          </v-col>
          <v-col cols="6" v-if="pageData.enable_seo_meta">
            <p class="mx-4">
              <i>The recommended length 50 to 60 characters</i>
            </p>
            <v-text-field
              label="SEO Meta Title"
              outlined
              v-model="pageData.seo_meta.title"
              class="mx-4 my-0"
            ></v-text-field>
          </v-col>
          <v-col cols="6" v-if="pageData.enable_seo_meta">
            <p class="mx-4">
              <i>The recommended length 120 to 155 characters</i>
            </p>
            <v-text-field
              label="SEO Meta Description"
              outlined
              v-model="pageData.seo_meta.description"
              class="mx-4 my-0"
            ></v-text-field>
          </v-col>
        </v-row>
        </v-form>
      </v-card>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn class="ma-2 white--text teal-color" to="/b2c/pages">Back</v-btn>
      <v-btn class="ma-2 white--text blue-color" @click="submitData"
        >Save Page
      </v-btn>
    </v-row>

    <!-- Partner Dialog for RM -->
    <v-dialog
      scrollable
      persistent
      v-model="partnerPopup"
      width="900px"
      max-width="100%"
    >
      <v-card>
        <v-card-title class="headline">Add new Partner</v-card-title>

        <v-card-text class="form_bg pa-8">
          <v-form ref="partnerForm">
            <v-row>
              <v-col cols="12" v-if="partnerData.partner_id">
                <div class="d-flex justify-end">
                  <v-btn
                    class="remove-carousel-btn"
                    depressed
                    small
                    @click="deletePartnerPopup(partnerData.partner_id)"
                  >
                    <v-icon color="#F44336">mdi-trash-can-outline</v-icon>
                    Remove Partner
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="5" md="3" sm="4">
                <div class="partner-popup-logo-main">
                  <image-upload
                    @upload="
                      (data) => {
                        partnerData.logoImage = data;
                      }
                    "
                    @remove="
                      () => {
                        partnerData.logoImage = null;
                        partnerData.logoPath = null;
                      }
                    "
                    :image_path="partnerData.logoPath"
                    :height="100"
                    :width="100"
                    defaultImage="default"
                    :isRemoveIcon="false"
                    :isB2cCarousel="true"
                    ref="partnerLogo"
                  ></image-upload>
                  <p class="text-center font-weight-bold py-1 mb-0">
                    Select Logo
                  </p>
                </div>
              </v-col>
              <v-col
                cols="7"
                md="6"
                sm="5"
                class="d-flex align-center justify-center"
              >
                <v-text-field
                  v-model="partnerData.title"
                  outlined
                  background-color="#fff"
                  label="Title*"
                  required
                  :rules="[(v) => !!v || 'title is required']"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="mt-5"
                v-if="checkWritePermission($modules.outlet.management.slug)"
              >
                <v-autocomplete
                  :items="outlets"
                  v-model="partnerData.outlet_id"
                  item-value="id"
                  item-text="name"
                  label="Outlets"
                  outlined
                  background-color="#fff"
                >
                  <!-- <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggleSelect()">
                      <v-list-item-action>
                        <v-icon
                          :color="outletIds.length > 0 ? 'indigo darken-4' : ''"
                          >{{ getIcon() }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template> -->
                  <!-- <template
                    v-if="outletIds.length == outlets.length"
                    v-slot:selection="{ index }"
                  >
                    <span v-if="index == 0">All Outlets</span>
                  </template>
                  <template v-else v-slot:selection="{ item, index }">
                    <span v-if="index == 0">{{ item.name }}</span>
                    <span v-if="index == 1">, {{ item.name }}</span>
                    <span v-if="index == 2">, ...</span>
                  </template> -->
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-switch
                  v-model="partnerData.is_enabled"
                  label="Enable/Disable"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  hide-details=""
                  v-model="partnerData.mobile"
                  outlined
                  background-color="#fff"
                  label="Mobile"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  hide-details=""
                  v-model="partnerData.whatsapp"
                  outlined
                  background-color="#fff"
                  label="Whatsapp"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  hide-details=""
                  v-model="partnerData.email"
                  outlined
                  background-color="#fff"
                  label="Email"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-switch
                  v-model="partnerData.enable_booking_form"
                  label="Enable Booking form button"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="partnerData.location"
                  outlined
                  background-color="#fff"
                  label=" Google Map Location"
                  placeholder="<iframe src.."
                  hint="Paste Google Map Embed Code"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  hide-details=""
                  v-model="partnerData.allLocation"
                  outlined
                  background-color="#fff"
                  label="External Location URL"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3 class="pb-4">Description</h3>
                <text-editor
                  @complete="
                    (val) => {
                      partnerData.description = val;
                    }
                  "
                  :message="partnerDataDesc"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <h3 class="py-4">Website</h3>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  hide-details=""
                  v-model="partnerData.website.text"
                  placeholder="Go to Website"
                  outlined
                  background-color="#fff"
                  label="Text"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  hide-details=""
                  v-model="partnerData.website.link"
                  outlined
                  background-color="#fff"
                  label="Link"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <h3 class="pt-4">Multiple Address</h3>
                <p class="text-caption pb-4 mb-0">All fields are optional</p>
              </v-col>
              <v-row
                v-for="(address, index) in partnerData.multipleAddress"
                :key="index"
              >
                <v-col cols="6" md="3">
                  <v-text-field
                    hide-details=""
                    v-model="address.mobile"
                    outlined
                    background-color="#fff"
                    label="Mobile"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                  <v-text-field
                    hide-details=""
                    v-model="address.address"
                    outlined
                    background-color="#fff"
                    label="Address"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2">
                  <v-text-field
                    hide-details=""
                    v-model="address.branchName"
                    outlined
                    background-color="#fff"
                    label="Branch Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                  <v-text-field
                    hide-details=""
                    v-model="address.timing"
                    outlined
                    background-color="#fff"
                    label="Timings"
                  ></v-text-field>
                </v-col>
                <v-col md="1" v-if="partnerData.multipleAddress.length > 1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="del_btn"
                        color="red"
                        @click="deleteAddress(index)"
                        x-small
                        dark
                        fab
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    Delete
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-col>
                <div class="add_btn py-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="blue-color"
                        fab
                        x-small
                        dark
                        @click="addNewAddress()"
                      >
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                    </template>
                    Add new Address
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3 class="py-4">Images</h3>
                <v-row>
                  <v-col cols="3">
                    <div class="upload-btn-main">
                      <!-- <input type="file" hidden ref="uploadImageInput" @change="imageSelectedHanlder($event, index)"> -->
                      <v-btn
                        depressed
                        large
                        class="upload-btn"
                        @click="addNewPartnerImage"
                      >
                        Add new Image
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    sm="6"
                    md="3"
                    cols="12"
                    v-for="(partnerImage, key) in partnerData.images"
                    :key="key"
                  >
                    <v-row>
                      <v-col cols="12" class="img-main">
                        <v-btn
                          elevation="3"
                          depressed
                          x-small
                          fab
                          class="remove-img-btn"
                          @click="removePartnerImage(key)"
                        >
                          <v-icon color="#fff">mdi-trash-can-outline</v-icon>
                        </v-btn>
                        <image-upload
                          @upload="
                            (data) => {
                              partnerImage.image = data;
                            }
                          "
                          @remove="
                            () => {
                              partnerImage.image = null;
                              partnerImage.image_path = null;
                            }
                          "
                          :image_path="partnerImage.image_path"
                          :height="200"
                          :width="100"
                          defaultImage="default"
                          :isRemoveIcon="false"
                          :isB2cCarousel="true"
                        ></image-upload>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="ma-2 white--text blue-color"
            @click="closePartnerPopupHandler"
            >Close
          </v-btn>
          <v-btn
            text
            class="ma-2 white--text teal-color"
            @click="saveOrUpdatePartner"
            >{{ partnerData.partner_id ? "Update" : "Save" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import TextEditor from "@/components/Marketing/TextEditor";
import PageCollapse from "@/components/B2C/PageCollapse";
import DeleteIcon from "@/assets/images/misc/delete-icon.svg";
import B2cTabs from "@/views/B2CConfigurations/B2cTabs.vue";
// import RichEditor from "@/components/Common/RichEditor.vue";

export default {
  components: {
    // RichEditor,
    B2cTabs,
    DeleteIcon,
    TextEditor,
    PageCollapse,
  },
  mounted() {
    this.btnShow = true;
    if (this.checkWritePermission(this.$modules.outlet.management.slug)) {
      this.loadOutlets();
    }
    if (this.$route.query && this.$route.query.pid) {
      this.pageId = this.$route.query.pid;
      this.getPageData();
    } else {
      this.getPageData();
    }
  },
  data() {
    return {
      // outletIds: [],
      outlets: [],
      outlet: null,
      // outlet_id:null,
      pageId: null,
      partnerPopup: false,
      currentCarouselId: null,
      carouselTypes: [
        {
          label: "Images",
          type: "images",
        },
        {
          label: "Portfolio",
          type: "portfolio",
        },
        {
          label: "List",
          type: "list",
        },
        {
          label: "Info Card",
          type: "info",
        },
        {
          label: "Downloadable",
          type: "downloadable",
        },
      ],
      page: 1,
      totalPages: 0,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      btnShow: false,
      venueServiceIds: [],
      linkedPages: ["Book now pop-up", "external website"],
      carousels: [],
      carouselsDesc: [],
      arCarouselsDesc: [],
      pageData: {
        id: 0,
        path: "new-page",
        menu_label: null,
        ar_menu_label: null,
        title: "",
        sub_title: "",
        ar_sub_title: "",
        description: "",
        ar_description: "",
        cover_image: null,
        cover_path: null,
        enable_seo_meta: false,
        is_default: false,
        enable_findus: false,
        seo_meta: {
          title: "",
          description: "",
        },
        enable_carousel: false,
        deletedCarousels: [],
        deletedImages: [],
        carousels: [],
        enable_collapse: true,
        collapse: [],
        card_type: null,
        parent_id: 0,
        layout_columns: 1,
      },
      home_desc: null,
      ar_home_desc: null,
      qube_configuration: "generic",
      is_arabic_enabled: false,
      config_enable_findus: false,
      partnerData: {
        partner_id: null,
        title: null,
        outlet_id: null,
        description: null,
        logoImage: null,
        logoPath: null,
        location: null,
        mobile: null,
        email: null,
        allLocation: null,
        whatsapp: null,
        multipleAddress: [
          {
            mobile: null,
            address: null,
            branchName: null,
            timing: null,
          },
        ],
        website: {
          text: null,
          link: null,
        },
        images: [],
        is_enabled: true,
        enable_booking_form: false,
      },
      deletedPartnerImages: [],
      partnerDataDesc: null,
      parentPages: [
        {
          id: 0,
          name: "Default Parent",
        },
      ],
      facilities: [],
    };
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    isPartnerLogoRefSet() {
      return !!this.$refs.partnerLogo;
    },
  },
  methods: {
    loadOutlets() {
      this.$http
        .get(`venues/outlets/short`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.outlets = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    toggleSelect() {
      this.$nextTick(() => {
        if (this.outlets.length == this.outletIds.length) {
          this.outletIds = [];
        } else {
          this.outletIds = this.outlets.map((item) => item.id);
        }
      });
    },
    getIcon() {
      if (this.outletIds.length == 0) return "mdi-checkbox-blank-outline";
      if (this.outlets.length == this.outletIds.length) return "mdi-close-box";
      return "mdi-minus-box";
    },
    addNewPartnerImage() {
      let data = {
        image: null,
        image_path: null,
        newImage: true,
      };

      this.partnerData.images.push(data);
    },
    removePartnerImage(key) {
      this.deletedPartnerImages.push(key);
      this.partnerData.images.splice(key, 1);
    },
    addNewAddress() {
      let data = {
        mobile: null,
        address: null,
        branchName: null,
        timing: null,
      };
      this.partnerData.multipleAddress.push(data);
    },
    deleteAddress(index) {
      this.partnerData.multipleAddress.splice(index, 1);
    },
    closePartnerPopupHandler() {
      this.partnerPopup = false;
    },
    getPartnerLogoImage(logo_path) {
      return this.s3BucketURL + logo_path;
    },
    showPartnerPopup(
      currentCarouselId,
      carouselIndex = null,
      partnerIndex = null,
      type = "add"
    ) {
      (this.outletIds = []), (this.currentCarouselId = currentCarouselId);
      // console.log("currentCarouselId: "+currentCarouselId);
      // console.log("carouselIndex: "+carouselIndex);
      // console.log("partnerIndex: " + partnerIndex);
      // console.log("type: "+type);
      if (type == "update" && this.currentCarouselId) {
        let partner =
          this.pageData.carousels[carouselIndex].partners[partnerIndex];

        let data = {
          partner_id: partner.id,
          title: partner.title,
          description: partner.description,
          logoPath: partner.logo_path,
          location: partner.location,
          mobile: partner.mobile,
          whatsapp: partner.whatsapp,
          email: partner.email,
          allLocation: partner.external_location_url,
          website: JSON.parse(partner.website),
          images: [],
          list: [],
          is_enabled: partner.is_enabled,
          enable_booking_form: partner.enable_booking_form,
          outlet_id: partner.outlet ? partner.outlet.outlet_id : null,
        };
        let multipleAddress =
          this.pageData.carousels[carouselIndex].partners[partnerIndex]
            .multiple_address;
        if (multipleAddress && multipleAddress != "null") {
          data.multipleAddress = JSON.parse(multipleAddress);
        } else {
          data.multipleAddress = [
            {
              mobile: null,
              address: null,
              branchName: null,
              timing: null,
            },
          ];
        }
        let dbImages =
          this.pageData.carousels[carouselIndex].partners[partnerIndex].images;
        if (dbImages && dbImages != "null") {
          let setImages = JSON.parse(dbImages);
          setImages.forEach((img) => {
            let imgData = {
              image: null,
              image_path: img,
            };
            data.images.push(imgData);
          });
        } else {
          data.images = [];
        }
        this.partnerDataDesc =
          this.pageData.carousels[carouselIndex].partners[
            partnerIndex
          ].description;
        this.partnerData = data;
        this.partnerPopup = true;
      } else {
        this.partnerData.description = null;
        this.partnerDataDesc = null;
        this.partnerPopup = true;
        this.$nextTick(() => {
          this.partnerData.description = null;
          this.partnerDataDesc = null;
          this.$refs.partnerLogo.cancel();
          this.partnerData.partner_id = null;
          this.partnerData.images = [];
          this.$refs.partnerForm.reset();
        });
      }
    },
    removeCarouselHandler(index, carouselId = null) {
      if (carouselId) {
        this.pageData.deletedCarousels.push(carouselId);
      }
      this.pageData.carousels.splice(index, 1);
      this.carouselsDesc.splice(index, 1);
      if (this.arCarouselsDesc.length) {
        this.arCarouselsDesc.splice(index, 1);
      }
    },

    removeImgHandler(carouselIndex, imageIndex, imageId) {
      this.pageData.deletedImages.push(imageId);
      this.pageData.carousels[carouselIndex].images.splice(imageIndex, 1);
    },

    addNewCarouselHandler() {
      let data = {
        title: "ADD TITLE",
        description: null,
        ar_title: null,
        ar_description: null,
        is_enabled: true,
        position: null,
        is_linked: false,
        link_text: null,
        link_page: null,
        images: [],
        type: "images",
        website: "",
        mobile_number: "",
      };
      if (this.pageData.carousels.length > 0) {
        let lastCarousel = this.pageData.carousels.slice(-1)[0];
        data.position = lastCarousel.position + 1;
      } else {
        data.position = 1;
      }
      this.carouselsDesc.push(null);
      this.arCarouselsDesc.push(null);
      this.pageData.carousels.push(data);
    },

    addNewImageHandler(index) {
      let data = {
        subtitle: null,
        ar_subtitle: null,
        image: null,
        image_path: null,
        position: this.pageData.carousels[index].images.length + 1,
        newImage: true,
      };

      this.pageData.carousels[index].images.push(data);
    },
    addNewPointHandler(index) {
      if (!this.pageData.carousels[index].list) {
        this.pageData.carousels[index].list = [];
      }
      this.pageData.carousels[index].list = [
        ...this.pageData.carousels[index].list,
        {
          text: null,
          ar_text: null,
        },
      ];
      this.$forceUpdate();
    },
    removePointHandler(index, listIndex) {
      this.pageData.carousels[index].list.splice(listIndex, 1);
      this.$forceUpdate();
    },
    setDescriptionContent(content) {
      this.pageData.description = content;
    },

    setArDescriptionContent(content) {
      this.pageData.ar_description = content;
    },

    setCarouselDescription(index, content) {
      this.pageData.carousels[index].description = content;
    },

    setArCarouselDescription(index, content) {
      this.pageData.carousels[index].ar_description = content;
    },
    getPageData() {
      this.pageData.additional_pages = [];
      this.showLoader("Loading");
      let pid = this.pageId ? this.pageId : 0;
      this.$http
        .get(`venues/b2c/additional/page/` + pid)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.facilities = [];
            this.hideLoader();
            if (response.data.data) {
              const data = response.data.data;
              if (data.configuration) {
                this.is_arabic_enabled = data.configuration.enable_arabic;
                this.qube_configuration = data.configuration.qube_configuration;
                this.config_enable_findus = data.configuration.enable_findus;
                this.facilities = data.configuration.facilities;
                if (
                  data.configuration.pages_path &&
                  data.configuration.pages_path.length > 0
                ) {
                  data.configuration.pages_path.forEach((page) => {
                    this.linkedPages.push(page.path);
                    if (page.is_default == 0) {
                      this.parentPages.push({
                        id: page.id,
                        name: page.menu_label,
                      });
                    }
                  });
                }
              }
              if (data.page) {
                this.pageData.id = data.page.id;
                this.pageData.menu_label = data.page.menu_label;
                this.pageData.ar_menu_label = data.page.ar_menu_label;
                this.pageData.path = data.page.path;
                this.pageData.title = data.page.title;
                this.pageData.sub_title = data.page.sub_title;
                this.pageData.description = data.page.description;
                this.pageData.ar_title = data.page.ar_title;
                this.pageData.ar_sub_title = data.page.ar_sub_title;
                this.pageData.ar_description = data.page.ar_description;
                this.pageData.isJustified = data.page.isJustified;
                this.pageData.enable_carousel = data.page.enable_carousel;
                this.pageData.enable_seo_meta = data.page.enable_seo_meta;
                this.pageData.is_default = data.page.is_default;
                this.pageData.parent_id = data.page.parent_id;
                this.pageData.show_on_homepage = data.page.show_on_homepage;
                this.pageData.enable_findus = data.page.enable_findus;
                this.pageData.carousels = data.page.carousels;
                this.pageData.collapse = data.page.carousels;
                this.pageData.cover_path = data.page.cover_image_url;
                this.pageData.card_type = data.page.card_type;
                this.pageData.layout_columns = data.page.layout_columns;

                if (data.page.description) {
                  this.home_desc = data.page.description;
                }

                if (data.page.ar_description) {
                  this.ar_home_desc = data.page.ar_description;
                }
                if (data.page.seo_meta) {
                  this.pageData.seo_meta = JSON.parse(data.page.seo_meta);
                }
                if (this.pageData.carousels.length > 0) {
                  this.pageData.carousels.forEach((carousel) => {
                    this.carouselsDesc.push(carousel.description);
                    if (carousel.ar_description) {
                      this.arCarouselsDesc.push(carousel.ar_description);
                    }
                  });
                }
              }
              this.$forceUpdate();
            }
          } else {
            console.log("page not found");
          }
        })
        .catch((error) => {
          this.showError("Page not found");
          console.log(error);
          this.hideLoader();
          this.$router.push("/b2c/pages");
        });
    },
    isShowCarouselFunc() {
      if (
        this.qube_configuration != "afh" &&
        (this.pageData.path == "home" || this.pageData.is_default == 0)
      ) {
        return true;
      } else if (
        this.qube_configuration == "afh" &&
        this.pageData.path == "home"
      ) {
        return true;
      } else {
        return false;
      }
    },
    submitData() {
      if (!this.$refs.pageForm.validate()){
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader("Saving");

      let formData = new FormData();

      if (this.pageData) {
        if (this.pageData.id) {
          formData.append("id", this.pageData.id ? this.pageData.id : null);
        }
        if (this.pageData.path) {
          formData.append("path", this.pageData.path);
        }
        formData.append("menu_label", this.pageData.menu_label);
        if (this.pageData.title) {
          formData.append("title", this.pageData.title);
        }
        if (this.pageData.sub_title && this.pageData.sub_title != null) {
          formData.append("sub_title", this.pageData.sub_title);
        }
        if (this.pageData.description && this.pageData.description != null) {
          formData.append("description", this.pageData.description);
        }
        formData.append("isJustified", this.pageData.isJustified || false);
        formData.append("layout_columns", this.pageData.layout_columns);
        formData.append(
          "enable_carousel",
          this.pageData.enable_carousel ? true : false
        );
        if (this.pageData.is_default && this.pageData.path != "home") {
          formData.append(
            "show_on_homepage",
            this.pageData.show_on_homepage ? true : false
          );
        }
        formData.append("parent_id", this.pageData.parent_id);
        /** Find Us Enable/Disable */
        if (this.config_enable_findus && this.pageData.enable_findus) {
          formData.append("enable_findus", true);
        } else {
          formData.append("enable_findus", false);
        }

        if (this.is_arabic_enabled) {
          formData.append("is_arabic_enabled", true);
          if (this.pageData.ar_menu_label) {
            formData.append("ar_menu_label", this.pageData.ar_menu_label);
          }
          if (this.pageData.ar_title) {
            formData.append("ar_title", this.pageData.ar_title);
          }
          if (
            this.pageData.ar_sub_title &&
            this.pageData.ar_sub_title != null
          ) {
            formData.append("ar_sub_title", this.pageData.ar_sub_title);
          }
          if (
            this.pageData.ar_description &&
            this.pageData.ar_description != null
          ) {
            formData.append("ar_description", this.pageData.ar_description);
          }
        }

        /** Seo Meta Tag for Home */
        if (this.pageData.enable_seo_meta) {
          formData.append("enable_seo_meta", true);
        } else {
          formData.append("enable_seo_meta", false);
        }
        if (this.pageData.seo_meta.title) {
          formData.append("seo_meta[title]", this.pageData.seo_meta.title);
        } else {
          formData.append("seo_meta[title]", "");
        }
        if (this.pageData.seo_meta.description) {
          formData.append(
            "seo_meta[description]",
            this.pageData.seo_meta.description
          );
        } else {
          formData.append("seo_meta[description]", "");
        }

        if (this.pageData.cover_image) {
          formData.append("cover_image", this.pageData.cover_image);
        }
        if (this.pageData.card_type) {
          formData.append("card_type", this.pageData.card_type);
        }
        this.pageData.deletedCarousels.forEach((dCar, index) => {
          formData.append("deletedCarousels[" + index + "]", dCar);
        });
        this.pageData.deletedImages.forEach((dImg, index) => {
          formData.append("deletedImages[" + index + "]", dImg);
        });
        this.pageData.carousels.forEach((carousel, index) => {
          formData.append(
            "carousels[" + index + "][title]",
            carousel.title === null ? "" : carousel.title
          );
          formData.append(
            "carousels[" + index + "][description]",
            carousel.description === null ? "" : carousel.description
          );

          formData.append(
            "carousels[" + index + "][background]",
            carousel.background
          );
          formData.append("carousels[" + index + "][type]", carousel.type);

          formData.append(
            "carousels[" + index + "][website]",
            carousel.website
          );
          formData.append(
            "carousels[" + index + "][mobile_number]",
            carousel.mobile_number
          );
          formData.append(
            "carousels[" + index + "][list]",
            JSON.stringify(carousel.list)
          );
          if (this.is_arabic_enabled && carousel.ar_title) {
            formData.append(
              "carousels[" + index + "][ar_title]",
              carousel.ar_title === null ? "" : carousel.ar_title
            );
          }
          if (this.is_arabic_enabled && carousel.ar_description) {
            formData.append(
              "carousels[" + index + "][ar_description]",
              carousel.ar_description === null ? "" : carousel.ar_description
            );
          }
          formData.append(
            "carousels[" + index + "][is_enabled]",
            carousel.is_enabled == "1" || carousel.is_enabled == true
              ? true
              : false
          );
          formData.append(
            "carousels[" + index + "][is_linked]",
            carousel.is_linked == "1" || carousel.is_linked == true
              ? true
              : false
          );
          if (carousel.is_linked) {
            formData.append(
              "carousels[" + index + "][link_text]",
              carousel.link_text
            );
            formData.append(
              "carousels[" + index + "][link_page]",
              carousel.link_page
            );
            if (
              carousel.link_page &&
              carousel.link_page == "external website" &&
              carousel.external_link &&
              carousel.external_link != null
            ) {
              formData.append(
                "carousels[" + index + "][external_link]",
                carousel.external_link
              );
            }
            if (this.is_arabic_enabled) {
              formData.append(
                "carousels[" + index + "][ar_link_text]",
                carousel.ar_link_text === null ? "" : carousel.ar_link_text
              );
            }
          }
          if (carousel.id) {
            formData.append(
              "carousels[" + index + "][carousel_id]",
              carousel.id
            );
          } else {
            formData.append("carousels[" + index + "][carousel_id]", null);
          }
          formData.append(
            "carousels[" + index + "][position]",
            carousel.position
          );
          carousel.images.forEach((row, key) => {
            if (row.id) {
              formData.append(
                "carousels[" + index + "][images][" + key + "][image_id]",
                row.id
              );
            } else {
              formData.append(
                "carousels[" + index + "][images][" + key + "][image_id]",
                null
              );
            }
            if (row.newImage) {
              formData.append(
                "carousels[" + index + "][images][" + key + "][newImage]",
                true
              );
            } else {
              formData.append(
                "carousels[" + index + "][images][" + key + "][newImage]",
                false
              );
            }
            formData.append(
              "carousels[" + index + "][images][" + key + "][subtitle]",
              row.subtitle
            );
            if (row.ar_subtitle) {
              formData.append(
                "carousels[" + index + "][images][" + key + "][ar_subtitle]",
                row.ar_subtitle
              );
            }
            formData.append(
              "carousels[" + index + "][images][" + key + "][position]",
              row.position
            );
            if (row.image) {
              formData.append(
                "carousels[" + index + "][images][" + key + "][image]",
                row.image
              );
            } else {
              formData.append(
                "carousels[" + index + "][images][" + key + "][image_path]",
                row.image_path
              );
            }

            if (carousel.type === "downloadable") {
              formData.append(
                "carousels[" + index + "][images][" + key + "][link_page]",
                row.link_page
              );
              formData.append(
                "carousels[" + index + "][images][" + key + "][link_text]",
                row.subtitle
              );
            }
          });
        });
      }
      this.$http
        .post(`venues/b2c/additional/page`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=${form._boundary}",
          },
        })
        .then((response) => {
          this.hideLoader();
          this.carouselsDesc = [];
          this.arCarouselsDesc = [];

          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Configuration saved successfully.");
            this.pageId = response.data.data.id;
            this.getPageData();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },

    saveOrUpdatePartner() {
      if (!this.$refs.partnerForm.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      if (!this.partnerData.logoImage && !this.partnerData.logoPath) {
        this.showError("Logo image is required");
        return;
      }
      if (!this.currentCarouselId) {
        this.showError("Something went rong please try again");
        return;
      }
      this.showLoader(
        this.partnerData.partner_id ? "Updating partner" : "Saving Changes..."
      );
      let formData = new FormData();

      this.deletedPartnerImages.forEach((dImg, index) => {
        formData.append("deletedPartnerImages[" + index + "]", dImg);
      });
      formData.append(
        "carousel_id",
        this.currentCarouselId === null ? "" : this.currentCarouselId
      );
      formData.append("title", this.partnerData.title);
      if (
        this.partnerData.outlet_id != undefined &&
        this.partnerData.outlet_id != null
      )
        formData.append("outlet_id", this.partnerData.outlet_id);
      formData.append(
        "description",
        this.partnerData.description === null
          ? ""
          : this.partnerData.description
      );
      if (this.partnerData.logoImage) {
        formData.append("logoImage", this.partnerData.logoImage);
      }
      formData.append("logoPath", this.partnerData.logoPath);
      formData.append(
        "location",
        this.partnerData.location === null ? "" : this.partnerData.location
      );
      formData.append(
        "mobile",
        this.partnerData.mobile === null ? "" : this.partnerData.mobile
      );
      formData.append(
        "whatsapp",
        this.partnerData.whatsapp === null ? "" : this.partnerData.whatsapp
      );
      formData.append(
        "email",
        this.partnerData.email === null ? "" : this.partnerData.email
      );
      formData.append(
        "allLocation",
        this.partnerData.allLocation === null
          ? ""
          : this.partnerData.allLocation
      );
      formData.append(
        "partner_id",
        this.partnerData.partner_id === null ? "" : this.partnerData.partner_id
      );

      // remove empty rows from multiple address
      let filteredMultipleAddress = this.partnerData.multipleAddress.filter(
        (obj) => {
          return Object.values(obj).some((value) => Boolean(value));
        }
      );

      filteredMultipleAddress.forEach((addr, index) => {
        formData.append(
          "multipleAddress[" + index + "][mobile]",
          addr.mobile === null ? "" : addr.mobile
        );
        formData.append(
          "multipleAddress[" + index + "][address]",
          addr.address === null ? "" : addr.address
        );
        formData.append(
          "multipleAddress[" + index + "][branchName]",
          addr.branchName === null ? "" : addr.branchName
        );
        formData.append(
          "multipleAddress[" + index + "][timing]",
          addr.timing === null ? "" : addr.timing
        );
      });
      formData.append(
        "website[text]",
        this.partnerData.website.text === null
          ? ""
          : this.partnerData.website.text
      );
      formData.append(
        "website[link]",
        this.partnerData.website.link === null
          ? ""
          : this.partnerData.website.link
      );
      formData.append("is_enabled", this.partnerData.is_enabled);
      formData.append(
        "enable_booking_form",
        this.partnerData.enable_booking_form
      );
      if (this.partnerData.images.length > 0) {
        this.partnerData.images.forEach((row, key) => {
          if (row.newImage) {
            formData.append("images[" + key + "][newImage]", true);
          } else {
            formData.append("images[" + key + "][newImage]", false);
          }

          if (row.image) {
            formData.append("images[" + key + "][image]", row.image);
          } else {
            formData.append(
              "images[" + key + "][image_path]",
              row.image_path === null ? "" : row.image_path
            );
          }
        });
      } else {
        formData.append("images[]", []);
      }

      this.$http
        .post(`venues/b2c/configurations/partners`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=${form._boundary}",
          },
        })
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status == true) {
            this.partnerPopup = false;
            this.$refs.partnerForm.reset();
            // this.getData();
            // this.carouselsDesc = [];
            this.showSuccess(
              this.partnerData.partner_id
                ? "Partner Updated successfully"
                : "Partner Added successfully"
            );
            this.getPageData();
            // location.reload();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    removePartnerHandler(partner_id) {
      if (partner_id) {
        this.showLoader("Removing Partner");

        this.$http
          .post(`venues/b2c/configurations/remove-partner`, {
            partner_id: partner_id,
          })
          .then((response) => {
            this.hideLoader();
            if (response.status == 200 && response.data.status == true) {
              const index = this.pageData.carousels.findIndex(
                (item) => item.id === this.currentCarouselId
              );
              const filterPartners = this.pageData.carousels[
                index
              ].partners.filter((item) => item.id !== partner_id);
              this.pageData.carousels[index].partners = filterPartners;
              this.partnerPopup = false;
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
      } else {
        this.showError("Invalid Partner");
      }
    },
    deletePartnerPopup(partnerId) {
      this.confirmModel = {
        id: partnerId,
        title: `Do you want to delete this Partner?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action?",
        type: "delete_partner",
      };
    },
    confirmActions(data) {
      if (data.type == "delete_partner") {
        this.removePartnerHandler(data.id);
      }
    },
  },
};
</script>

<style scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}

.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.upload-btn-main {
  background-color: #f4f8fa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px dashed #d0d0d0;
  width: 190px;
  height: 80px;
}

.upload-btn {
  background-color: #e5f0f6 !important;
  color: #16b4b3 !important;
  font-weight: 600;
  font-size: 13px;
  width: 135px;
  text-transform: none;
}

.img-main {
  position: relative;
  border-radius: 8px;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 20px;
}

.img-main:hover .remove-img-btn {
  display: block;
}

.remove-img-btn {
  display: none;
  background-color: #c62828 !important;
  border-radius: 50% !important;
  position: absolute;
  z-index: 1;
  right: 20px;
  top: -5px;
}

.remove-carousel-btn {
  color: #f44336 !important;
  text-transform: none;
  background-color: #ffeaea !important;
}

.carousel-subtitle {
  font-weight: 400;
  color: #adaaaa;
  letter-spacing: 1px;
}

.partners-main {
  max-width: 600px;
  width: 100%;
  display: flex;
}

.partner-thumb {
  cursor: pointer;
  width: 100px;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.partner-thumb-gray {
  filter: grayscale(1);
  opacity: 0.4;
}

.partner-popup-logo-main {
  background-color: #fff;
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 1px;
}

.partner-erorr-note {
  color: #e53935;
}
</style>
