<template>
    <div>
        <v-dialog
        scrollable
        persistent
        v-model="collapseSectionPopup"
        width="70%"
        max-width="100%"
        >
            <v-card height="100%">
                <v-form ref="form"  v-model="valid" lazy-validation>
                    <v-card-title class="headline">Add New Section
                        <v-btn
                            text
                            x-small
                            class="ma-2 white--text blue-color"
                            href="https://devapi-qportal-uae.s3.me-central-1.amazonaws.com/documents/section-types.pdf"
                            target="_blank"
                            >View Section Types</v-btn
                        >

                    </v-card-title>
                    <v-card-text class="pa-8">
                        <v-row>
                            <v-col cols="3" class="pb-0">
                                <v-switch
                                    class="mx-0 my-0"
                                    v-model="collapseSection.enable_section"
                                    label="Enable Section"
                                ></v-switch>
                            </v-col>
                            <v-col cols="4" class="pb-0">
                                <v-select
                                    v-model="collapseSection.section_type"
                                    :items="sectionTypes"
                                    item-value="id"
                                    outlined
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    background-color="#fff"
                                    item-text="name"
                                    :label="`Section Type*`"
                                    required
                                    :rules="[(v) => !!v || 'Section type is required']"
                                    ></v-select>
                            </v-col>
                            <v-col cols="4" class="pb-0" v-if="collapseSection.section_type == 11">
                                <v-select
                                    v-model="collapseSection.facility_id"
                                    :items="facilities"
                                    item-value="id"
                                    outlined
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    background-color="#fff"
                                    item-text="name"
                                    :label="`Event Facilities*`"
                                    required
                                    :rules="[(v) => !!v || 'Event Facility type is required']"
                                    >
                                </v-select>
                            </v-col>
                            <v-col cols="4" class="pb-0" v-if="collapseSection.section_type == 12">
                                <v-select
                                    v-model="collapseSection.venue_service_id"
                                    :items="venueServices"
                                    item-value="id"
                                    outlined
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    background-color="#fff"
                                    item-text="name"
                                    :label="`Service*`"
                                    required
                                    :rules="[(v) => !!v || 'Venue Service is required']"
                                    >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="collapseSection.section_type == 2 || collapseSection.section_type == 4 || collapseSection.section_type == 5 || collapseSection.section_type == 11 || collapseSection.section_type == 12">
                            <v-col cols="6" class="pb-0">
                                <v-text-field
                                    label="Title *"
                                    outlined
                                    v-model="collapseSection.title"
                                    class="mx-0 my-0"
                                    required
                                    :rules="[(v) => !!v || 'Title is required']"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="pb-0" v-if="is_arabic_enabled">
                                <v-text-field
                                    label="Title (Ar)*"
                                    outlined
                                    v-model="collapseSection.ar_title"
                                    class="mx-0 my-0"
                                    required
                                    :rules="[(v) => !!v || 'Title is required']"
                                    dir="rtl"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="collapseSection.section_type == 4">
                            <v-col cols="6" class="pb-0">
                                <v-text-field
                                    label="Subtitle"
                                    outlined
                                    v-model="collapseSection.sub_title"
                                    class="mx-0 my-0"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="pb-0" v-if="is_arabic_enabled">
                                <v-text-field
                                    label="Subtitle (Ar)"
                                    outlined
                                    v-model="collapseSection.ar_sub_title"
                                    class="mx-0 my-0"
                                    dir="rtl"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="![11,12,13].includes(collapseSection.section_type)">
                           <v-col>
                                <h3 class="pb-4">Description</h3>
                                 <text-editor
                                    @complete="setDescriptionContent"
                                    :message="description"
                                    style="width: 100%"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="is_arabic_enabled && ![11,12,13].includes(collapseSection.section_type)">
                            <v-col>
                                <h3 class="pb-4">Description (Ar)</h3>
                                 <text-editor
                                    @complete="setArDescriptionContent"
                                    :message="ar_description"
                                    style="width: 100%"
                                    dir="rtl"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="collapseSection.section_type == 3 || collapseSection.section_type == 4 || collapseSection.section_type == 5">
                          <v-col cols="6" class="pb-0">
                            <image-upload
                                @upload="
                                (data) => {
                                    collapseSection.cover_image = data;
                                }
                                "
                                @remove="
                                () => {
                                    collapseSection.cover_image = null;
                                }
                                "
                                :image_path="collapseSection.cover_path"
                                :height="300"
                                class="mr-5"
                                defaultImage="event"
                                :maxAspectRatio="16 / 9"
                            ></image-upload>
                            <p class="text-center mt-0 mb-8"><b>Section image</b></p>
                        </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            class="ma-2 white--text blue-color"
                            @click="closeCollapseSectionPopupHandler"
                            >Close</v-btn
                        >
                        <v-btn
                            text
                            class="ma-2 white--text teal-color"
                            @click="saveOrUpdateCollapseSection">{{ collapseId? "Update" : "Save" }}</v-btn
                        >
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import TextEditor from "@/components/Marketing/TextEditor";
export default {
    name: "CollapseSectionForm",
    components: {
        TextEditor
    },
    props: {
        collapseId: { type: Number, default: null },
        sectionId: { type: Number, default: null },
        collapseSectionPopup: { type: Boolean, default: false },
        is_arabic_enabled: { type: Number, default: 0 },
        facilities: { type: Array, default: function() { return [] } }
    },
    watch: {
        collapseSectionPopup: {
            immediate: true,
            handler(val) {
                this.description = null;
                this.ar_description = null;
                console.log(val);
                if (this.sectionId && this.collapseSectionPopup) {
                    this.getCollapseSection();
                }
            },
        }
    },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
  },
  data() {
        return {
            valid: true,
            sectionTypes: [
                { id: 1, name: "Description" },
                { id: 2, name: "Title & Description" },
                { id: 3, name: "Description & Image" },
                { id: 4, name: "Title, Description & Image" },
                { id: 5, name: "Card : Title, Description & Image" },
                { id: 11, name: "Event Module" },
                { id: 12, name: "Academy Module" },
                { id: 13, name: "Gallery Module" },
            ],
            allFacilities: [],
            collapseSection: {
                id: null,
                collapse_id: null,
                section_type: 1,
                facility_id:null,
                venue_service_id:null,
                title: "",
                ar_title: "",
                sub_title: "",
                ar_sub_title: "",
                description: "",
                ar_description: "",
                enable_section: false,
                is_arabic_enabled: 0,
                cover_image: null,
                cover_path: null,
            },
            description: "",
            ar_description: "",
        }
    },
    computed:{
      venueServices() {
        console.log(this.$store.getters.getVenueServices.data)
        return this.$store.getters.getVenueServices.data;
      }
    },
    methods: {
        saveOrUpdateCollapseSection() {
            if (!this.$refs.form.validate()) {
                this.showError("Please fill all required fields");
                return;
            }
            this.showLoader("Saving...");
            let formData = new FormData();
            console.log(this.collapseSection)
            if (this.collapseSection && this.collapseId) {
                formData.append("collapse_id", this.collapseId);
                if (this.collapseSection.id) {
                    formData.append("id", this.collapseSection.id ? this.collapseSection.id : null);
                }
                if (this.collapseSection.facility_id) {
                    formData.append("facility_id", this.collapseSection.facility_id ? this.collapseSection.facility_id : null);
                }
                if (this.collapseSection.venue_service_id) {
                    formData.append("venue_service_id", this.collapseSection.venue_service_id);
                }
                if (this.collapseSection.title) {
                    formData.append("title", this.collapseSection.title);
                }
                if (this.collapseSection.sub_title) {
                    formData.append("sub_title", this.collapseSection.sub_title);
                }
                if (this.description) {
                    formData.append("description", this.description);
                }
                formData.append("section_type", this.collapseSection.section_type);
                formData.append("enable_section", this.collapseSection.enable_section ? true : false);

                if (this.is_arabic_enabled) {
                    formData.append("is_arabic_enabled", true);
                    if (this.collapseSection.ar_title) {
                        formData.append("ar_title", this.collapseSection.ar_title);
                    }
                    if (this.collapseSection.ar_sub_title) {
                        formData.append("ar_sub_title", this.collapseSection.ar_sub_title);
                    }
                    if (this.ar_description) {
                        formData.append("ar_description", this.ar_description);
                    }
                }

                if (this.collapseSection.cover_image) {
                    formData.append("cover_image", this.collapseSection.cover_image);
                }
                this.$http.post(`venues/b2c/additional/page/collapse/section`, formData,{
                    headers: {
                        "Content-Type": "multipart/form-data; boundary=${form._boundary}",
                    },
                }).then((response) => {
                    this.hideLoader();
                    this.collapseSection = {};
                    if (response.status == 200 && response.data.status == true) {
                        this.showSuccess("Collapse saved successfully.");
                        this.resetFormFields();
                        this.closeCollapseSectionPopupHandler();
                    }
                })
                .catch((error) => {
                    this.hideLoader();
                    this.errorChecker(error);
                });
            } else {
                this.hideLoader();
                this.showError("Data not found");
            }
        },
        closeCollapseSectionPopupHandler() {
            this.resetFormFields();
            this.$emit("closeCollapseSection");
        },
        setDescriptionContent(content) {
          this.description = content;
        },
        setArDescriptionContent(content) {
          this.ar_description= content;
        },
        getCollapseSection() {
            if (this.sectionId) {
                this.showLoader("Loading Section");
                this.$http.get(`venues/b2c/additional/page/collapse/section/${this.sectionId}`).then((response) => {
                    this.hideLoader();
                    if (response.status == 200 && response.data.status == true) {
                        const data = response.data.data;
                        this.collapseSection = data;
                        if (data.description) {
                            this.description = data.description;
                        }
                        if (data.ar_description) {
                            this.ar_description = data.ar_description;
                        }
                        if(data.cover_image_url)
                        this.collapseSection.cover_path = data.cover_image_url;
                    }
                })
                .catch((error) => {
                    this.hideLoader();
                    this.errorChecker(error);
                });
            }
        },
        resetFormFields() {
            let ob = {
                id: null,
                collapse_id: null,
                section_type: 1,
                title: "",
                ar_title: "",
                sub_title: "",
                ar_sub_title: "",
                description: "",
                ar_description: "",
                enable_section: false,
                is_arabic_enabled: 0,
                cover_image: null,
                cover_path: null,
            }
            this.description = null;
            this.ar_description = null;
            this.collapseSection = Object.assign({},ob);
        },

    },
};
</script>