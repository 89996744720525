<template>
  <div class="collapse-list">
    <v-row>
      <v-col cols="10"><div class="pa-4"><h2>Collapse</h2></div></v-col>
      <v-col cols="2" class="text-right">
        <v-btn
          small
          text
          outlined
          class="white--text ma-4 blue-color"
          @click="addNewCollapse"
          :disabled="pageId == 0?true:false"

        >
          <v-icon color="#fff" small>mdi-plus</v-icon>
          Add Collapse
        </v-btn>

      </v-col>
    </v-row>
    <div class="pa-4">
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(collapse, index) in collapseList"
          :key="index">
          <v-expansion-panel-header style="background-color:rgb(151 151 151 / 12%)">
            <v-row>
              <v-col cols="8">
                <h3 class="pa-3">
                  <span
                    v-if="collapse.title && collapse.title != 'null'"
                    class="carousel-subtitle"
                    >{{collapse.menu_label}} <sub>({{ collapse.path }})</sub>
                  </span>
                </h3>
              </v-col>
              <v-col cols="4" class="text-right">
                <v-btn
                  x-small
                  fab
                  text
                  outlined
                  class="white--text ma-2 blue-color"
                  title="Add New Section"
                  @click="addNewSection(collapse.id)"
                >
                  <v-icon color="#fff">mdi-plus</v-icon>
                </v-btn>
                <v-btn
                  @click="editCollapse(collapse)"
                  fab
                  x-small
                  title="Edit Collapse"
                  class="white--text ma-2 teal"
                  >
                  <v-icon color="white">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  @click="removeCollapsePopup(index)"
                  fab
                  x-small
                  title="Delete Collapse"
                  class="white--text ma-2 red-color"
                  >
                  <v-icon color="white">mdi-delete-outline</v-icon>
                </v-btn>
              </v-col>
             </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="collapse.sections && collapse.sections.length > 0">
              <v-row v-for="(section, sIndex) in collapse.sections" :key="`s_${sIndex}`">
                  <v-col cols="12" class="child-border">
                      <v-row class="mt-2 py-2 mr-2">
                          <v-col cols="8" class="py-0">
                            <h4 class="ml-4"><span class="mdi mdi-arrow-right"></span> Section {{ sIndex + 1}}: {{ section.title?section.title:' Type: '+section.section_type }}</h4>
                          </v-col>
                          <v-col cols="4" class="py-0 text-right">
                             <v-btn
                                @click="editSection(section)"
                                fab
                                x-small
                                title="Edit Section"
                                class="white--text ma-2 teal"
                                >
                                <v-icon color="white">mdi-pencil-outline</v-icon>
                            </v-btn>
                            <v-btn
                              @click="removeSectionPopup(index,sIndex)"
                              fab
                              x-small
                              title="Delete Section"
                               class="white--text ma-2 red-color"
                              >
                              <v-icon color="white">mdi-delete-outline</v-icon>
                            </v-btn>
                          </v-col>
                      </v-row>
                  </v-col>
              </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="pa-4">
      <PageCollapseForm
        :is_arabic_enabled="is_arabic_enabled"
        :pageId="pageId"
        :collapseData="currentCollapseData"
        :collapsePopup="collapsePopup"
        @closeCollapse="closeCollapse"/>
      <CollapseSectionForm
        :is_arabic_enabled="is_arabic_enabled"
        :collapseId="currentCollapseId"
        :sectionId="currentSectionId"
        :collapseSectionPopup="collapseSectionPopup"
        :facilities="facilities"
        @closeCollapseSection="closeCollapseSection"
      />

      <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null,currentCollapseId = null, currentSectionIndex = null"
      ></confirm-model>
    </div>
  </div>

</template>
<script>
import PageCollapseForm from "@/components/B2C/PageCollapseForm";
import CollapseSectionForm from "@/components/B2C/CollapseSectionForm";
export default {
  name:"PageCollapse",
  props: {
    pageId: { type: Number, default: 0 },
    is_arabic_enabled: { type: Number, default: 0 },
    facilities: { type: Array, default: function() { return [] } },
  },
  components: {
     PageCollapseForm,CollapseSectionForm
  },
  mounted() {
    if (this.pageId) {
       this.getPageCollapse();
    }
  },
  data() {
    return {
      collapsePopup: false,
      collapseSectionPopup:false,
      collapseList: [],
      currentCollapseId: null,
      currentCollapseData: null,
      currentSectionId: null,
      currentCollapseIndex: null,
      currentSectionIndex: null,
      confirmModel: {
          id: null,
          title: null,
          description: null,
      },
    }
  },
  methods: {
    getPageCollapse() {
      if (this.pageId) {
          this.showLoader("Loading Collapse");
          this.$http.get(`venues/b2c/additional/page/collapse/${this.pageId}`).then((response) => {
              this.hideLoader();
              if (response.status == 200 && response.data.status == true) {
                this.collapseList = response.data.data;
              }
          })
          .catch((error) => {
              this.hideLoader();
              this.errorChecker(error);
          });
      }
    },

    addNewCollapse() {
      this.collapsePopup = true;
    },
    editCollapse(collapse) {
      this.currentCollapseData =  Object.assign({},collapse);
      this.currentCarouselId = null;
      this.currentSectionId = null;
      this.collapsePopup = true;
    },
    removeCollapsePopup(collapseIndex) {
      this.currentCollapseIndex = collapseIndex;
      this.confirmModel = {
          id: collapseIndex,
          title: "Do you want to delete this collapse?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "removeCollapse",
      }
    },
    removeCollapseHandler() {
      if (this.currentCollapseIndex != null) {
        this.showLoader("Deleting Collapse");
        let collapseId = this.collapseList[this.currentCollapseIndex].id;
        if (collapseId) {
            this.$http.post(`venues/b2c/additional/page/collapse/${collapseId}`)
              .then((response) => {
                this.hideLoader();
                if (response.status == 200 && response.data.status == true) {
                   this.showSuccess("Collapse Deleted Successfully")
                  this.getPageCollapse();
                }
              })
              .catch((error) => {
                this.hideLoader();
                this.errorChecker(error);
              });
        }
      } else {
        this.showError("Invalid Section");
        return;
      }
    },
    closeCollapse() {
      this.collapsePopup = false;
      this.currentCollapseData = null;
      this.currentCarouselId = null;
      this.currentSectionId = null;
      this.getPageCollapse();
    },

    addNewSection(collapseId) {
      this.currentCollapseId = collapseId;
      this.currentSectionId = null;
      this.collapseSectionPopup = true;
    },
    editSection(section) {
      this.currentCollapseId = section.collapse_id;
      this.currentSectionId = section.id;
      this.collapseSectionPopup = true;
    },

    closeCollapseSection() {
      this.collapseSectionPopup = false;
      this.currentCollapseId = null;
      this.currentSectionId = null;
      this.getPageCollapse();
    },


    removeSectionPopup(collapseIndex, sectionIndex) {
      this.currentCollapseIndex = collapseIndex;
      this.currentSectionIndex = sectionIndex;
      this.confirmModel = {
          id: sectionIndex,
          title: "Do you want to delete this section?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "removeSection",
      }
    },
    removeSectionHandler() {
      if (this.currentCollapseIndex != null && this.currentSectionIndex != null) {
        this.showLoader("Deleting Section");
        let sectionId = this.collapseList[this.currentCollapseIndex].sections[this.currentSectionIndex].id;
        if (sectionId) {
            this.$http.post(`venues/b2c/additional/page/collapse/section/${sectionId}`)
              .then((response) => {
                this.hideLoader();
                if (response.status == 200 && response.data.status == true) {
                  this.showSuccess("Section Deleted Successfully")
                  this.getPageCollapse();
                }
              })
              .catch((error) => {
                this.hideLoader();
                this.errorChecker(error);
              });
        }
      } else {
        this.showError("Invalid Section");
        return;
      }
    },
    confirmActions(data) {
      if (data.type == "removeSection") {
        if (this.collapseList[this.currentCollapseIndex] && this.collapseList[this.currentCollapseIndex].sections && this.collapseList[this.currentCollapseIndex].sections[this.currentSectionIndex]) {
          this.removeSectionHandler();
        }
        this.$forceUpdate();
        this.currentCollapseIndex = null;
        this.currentSectionIndex = null;
        this.confirmModel.id = null;
      } else if (data.type == "removeCollapse") {
        if (this.collapseList[this.currentCollapseIndex]) {
          this.removeCollapseHandler();
        }
        this.$forceUpdate();
        this.currentCollapseIndex = null;
        this.currentSectionIndex = null;
        this.confirmModel.id = null;
      }
    }

  },
};
</script>
<style scope>
  .child-border {
    border-bottom: 1px solid #e2e2e2;
  }
</style>